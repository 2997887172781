/* Generated by Font Squirrel (http://www.fontsquirrel.com) on June 10, 2015 */



@font-face {
    font-family: 'chivoblack';
    src: url('chivo-black-webfont.eot');
    src: url('chivo-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('chivo-black-webfont.woff2') format('woff2'),
         url('chivo-black-webfont.woff') format('woff'),
         url('chivo-black-webfont.ttf') format('truetype'),
         url('chivo-black-webfont.svg#chivoblack') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoblack';
    src: url('lato-black-webfont.eot');
    src: url('lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('lato-black-webfont.woff2') format('woff2'),
         url('lato-black-webfont.woff') format('woff'),
         url('lato-black-webfont.ttf') format('truetype'),
         url('lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoregular';
    src: url('lato-regular-webfont.eot');
    src: url('lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('lato-regular-webfont.woff2') format('woff2'),
         url('lato-regular-webfont.woff') format('woff'),
         url('lato-regular-webfont.ttf') format('truetype'),
         url('lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'lobster_twobold';
    src: url('lobstertwo-bold-webfont.eot');
    src: url('lobstertwo-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('lobstertwo-bold-webfont.woff2') format('woff2'),
         url('lobstertwo-bold-webfont.woff') format('woff'),
         url('lobstertwo-bold-webfont.ttf') format('truetype'),
         url('lobstertwo-bold-webfont.svg#lobster_twobold') format('svg');
    font-weight: normal;
    font-style: normal;

}