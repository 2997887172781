@media screen and (min-width: 1024px) {
  .col-25-gutter {
    margin-top: 20px;
    width: 23.5%;
    margin-left: 2%;
    -webkit-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .col-25-gutter:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .col-25-gutter:nth-of-type(4n + 1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (max-width: 1024px) {
  .col-25-gutter {
    margin-top: 30px;
    width: 48.5%;
    margin-left: 2%;
  }
  .col-25-gutter:first-of-type {
    margin-left: 0;
  }
  .col-25-gutter:nth-of-type(2n + 1) {
    clear: left;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .col-25-gutter {
    margin-top: 30px;
    width: 100%;
    clear: left;
    margin-left: 0;
  }
  .col-25-gutter:first-of-type {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .col-25 {
    width: 25%;
    -webkit-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1024px) {
  .col-25 {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .col-25 {
    width: 100%;
  }
}

.col-33-gutter {
  margin-top: 30px;
  width: 31%;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 3.5%;
}
.col-33-gutter:nth-of-type(-n + 3) {
  margin-top: 0;
}
.col-33-gutter:nth-of-type(3n + 1) {
  margin-left: 0;
}

@media screen and (min-width: 600px) {
  .col-33 {
    width: 32%;
    margin-left: 3%;
  }
  .col-33:nth-of-type(3n + 1) {
    /* clear: left; */
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .col-33 {
    width: 100%;
    padding-top: 30px;
    margin-left: 0;
  }
}

.col-50 {
  width: 50%;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (max-width: 1024px) {
  .col-50 {
    width: 100%;
  }
}

.col-50-gutter {
  width: 49.4%;
  margin-left: 1.1%;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.col-50-gutter:first-of-type {
  margin-left: 0;
}
.col-50-gutter:nth-of-type(2n + 1) {
  clear: left;
  margin-left: 0;
}
@media screen and (max-width: 768px) {
  .col-50-gutter {
    width: 100%;
    margin-left: 0;
  }
}

.col-66 {
  width: 65%;
}
@media screen and (max-width: 768px) {
  .col-66 {
    width: 100%;
    padding-top: 30px;
  }
}

[class*='col-'] {
  float: left;
  display: inline-block;
}

/* Media Queries*/
/* SMARTPHONES PORTRAIT */
@media only screen and (min-width: 0px) {
  #menu_top_text {
    font-size: 0.6em;
  }
  #online_order_container {
    padding: 0.3em;
  }
  #order_container_inner {
    font-size: 0.75em;
    margin: 3em 0;
  }
  #online_order_top {
    top: -2.8em;
  }
  #online_order_bottom {
    bottom: -3.6em;
  }
  .news-item,
  #contact_info {
    width: 100%;
  }
  #legal_info {
    font-size: 0.8em;
  }
}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 550px) {
  body,
  html {
    font-size: 15px;
  }
  #online_order_container {
    padding: 1em;
  }
  #order_container_inner {
    font-size: inherit;
    margin: 0;
  }
  #online_order_top {
    top: -0.8em;
  }
  #online_order_bottom {
    bottom: -1em;
  }
  #menu_top_text {
    font-size: 1.12em;
  }
  .news-item,
  #contact_info {
    width: 100%;
  }
}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) {
  body,
  html {
    font-size: 22px;
  }
  .news-item,
  #contact_info {
    width: 100%;
  }
  #legal_info {
    font-size: 0.5em;
  }
}

/* TABLET LANDSCAPE / DESKTOP */
@media only screen and (min-width: 1130px) {
  body,
  html {
    font-size: 29px;
  }
}

/* General */
body,
html {
  overflow-x: auto;
}

body {
  background-color: #fdff2e;
  text-align: center;
  font-family: 'latoblack';
}

b {
  font-weight: bold;
  font-family: 'latoblack';
}

p {
  margin: 0;
  padding: 0;
  line-height: 1.2em;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-family: 'lobster_twobold';
}

h1 a {
  text-decoration: underline;
}

.red {
  color: #f00;
}

.black-border {
  border: 8px solid #000;
  background-color: #fff348;
  position: relative;
  font-size: 30px;
}
@media screen and (max-width: 768px) {
  .black-border {
    font-size: 18px;
    border: 6px solid #000;
  }
}

.square-button.red-bg {
  display: inline-block;
}
.square-button.red-bg.inline {
  color: #ffffff;
  padding: 10px 40px;
  margin: 10px 5px;
}
@media screen and (max-width: 768px) {
  .square-button.red-bg.inline {
    font-size: 20px;
    padding: 8px 20px;
  }
}

.square-button {
  border: 7px solid #000;
  padding: 10px;
  font-family: 'lobster_twobold';
  margin: 0 auto;
}

.red-bg {
  background: #ff0000;
}

.online-mod {
  border: 8px solid #000000;
  margin-bottom: 30px;
}

.online-mod h2 {
  color: #ffffff;
  margin: 20px 0 10px;
}
@media screen and (max-width: 768px) {
  .online-mod h2 {
    font-size: 20px;
  }
}

.online-mod p {
  font-size: 25px;
}

.online-mod .btn-yellow {
  background: #fdff2e;
  font-family: 'lobster_twobold';
  margin: 20px auto;
  padding: 5px 5%;
  border: 8px solid #000000;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .online-mod .btn-yellow {
    font-size: 20px;
    margin: 10px auto;
  }
}

.txt1 {
  max-width: 810px;
  margin: 30px auto 40px;
  display: inline-block;
  font-size: 30px;
  padding: 0 20px;
  line-height: 37px;
}
@media screen and (max-width: 768px) {
  .txt1 {
    margin: 20px auto;
    padding: 0;
    font-size: 24px;
    line-height: 30px;
  }
}

#main_site {
  margin: auto;
  width: 100%;
  max-width: 1200px;
}

.site-section {
  width: 92%;
  text-align: center;
  position: relative;
  margin: auto;
}

.section-head-img {
  max-width: 25%;
}

.section-head-img.news {
  margin: 30px 0 50px;
}

.standalone-header-img {
  margin-top: -1em;
  margin-bottom: 1.5em;
}

.dotted-border {
  font-size: 17px;
  letter-spacing: 3px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin: 1em 0;
}

/* Top */
#main_header_img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  #main_header_img {
    margin: 0 0 20px;
  }
}

img + #restaurant_hours p {
  font-size: 26px;
  line-height: 32px;
}
@media screen and (max-width: 768px) {
  img + #restaurant_hours p {
    font-size: 18px;
    line-height: 22px;
  }
}

#restaurant_hours {
  font-size: 22px;
  margin: -1.8em 0 2em;
}
@media screen and (max-width: 768px) {
  #restaurant_hours {
    margin: 0 0 15px;
  }
}
#restaurant_hours h2 {
  text-transform: uppercase;
  font-size: 38px;
}
@media screen and (max-width: 768px) {
  #restaurant_hours h2 {
    font-size: 28px;
  }
}
#restaurant_hours p {
  line-height: 28px;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  #restaurant_hours p {
    line-height: 24px;
    font-size: 15px;
  }
}
#restaurant_hours.no-top-margin {
  margin: 60px 0 50px;
}
@media screen and (max-width: 768px) {
  #restaurant_hours.no-top-margin {
    margin: 25px 0;
  }
}

#restaurant-open-hours {
  border: 9px solid #000;
  padding: 30px;
  margin: 80px auto;
  max-width: 804px;
}
#restaurant-open-hours p {
  margin-top: 0;
  line-height: 40px;
}
#restaurant-open-hours h2 {
  margin-top: 0;
  text-transform: uppercase;
}

#online_order_container {
  width: 95%;
  margin: 1em auto;
}

#order_container_inner {
  position: relative;
  height: 4em;
}

#online_small_text {
  font-size: 0.7em;
  display: inline-block;
}

.online-logo {
  position: absolute;
  height: 100%;
}

#online_logo_left {
  left: 0;
}

#titos_express_img {
  height: 100%;
}

#online_logo_right {
  right: 0;
}

#online_button {
  background-color: #ed1c24;
  color: #fff;
  margin-top: 1em;
}

#online_order_top {
  position: absolute;
  width: 100%;
}

#online_order_bottom {
  position: absolute;
  width: 100%;
  font-size: 0.86em;
}

#header_bottom {
  width: 83%;
  margin: auto;
  font-size: 0.96em;
}
@media screen and (max-width: 768px) {
  #header_bottom {
    margin: 0 auto 30px;
  }
}

#main_nav {
  font-size: 0.75em;
  position: relative;
  height: 5em;
  width: 100%;
  margin: 1.3em 0 30px;
}

.nav-item-outer {
  position: absolute;
  top: 0;
}

.nav-item-inner {
  position: relative;
  width: 5em;
  height: 5em;
  cursor: pointer;
  -webkit-border-radius: 5em;
  -moz-border-radius: 5em;
  border-radius: 5em;
}

.nav-text {
  background-color: #fdff2e;
  width: 100%;
  position: relative;
  top: 1.75em;
  padding: 0.25em 0;
}

#nav_menu {
  left: 0;
}

#nav_menu .nav-item-inner {
  background-color: #ed1c24;
}

#nav_about {
  left: 33%;
}

#nav_about .nav-item-inner {
  background-color: #a0d2ec;
  right: 33%;
}

#nav_news {
  right: 33%;
}

#nav_news .nav-item-inner {
  background-color: #050708;
  left: 33%;
}

#nav_location {
  right: 0;
}

#nav_location .nav-item-inner {
  background-color: #f89b5a;
}

/* Menu */
#menu_top_text {
  margin: 80px 0 30px;
  display: inline-block;
  clear: both;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  #menu_top_text {
    margin: 20px 0 10px;
  }
}

#menu_top_text span {
  padding: 17px 30px;
}
@media screen and (max-width: 768px) {
  #menu_top_text span {
    padding: 8px 15px;
    display: block;
  }
}

#menu {
  width: 100%;
}

.menu-page {
  font-family: 'latoregular';
  font-size: 0.8em;
  text-align: left;
  background-color: #fff;
  padding: 2.5em 4%;
  position: relative;
  margin: auto;
  margin-top: 1em;
  display: table;
}

.menu-col {
  display: table-cell;
  vertical-align: top;
}

.menu-side-col {
  width: 12%;
}

.menu-text-col {
  padding: 0 2% 0.6em;
  width: 72%;
}

.menu-header {
  text-align: center;
  margin: 0;
}

.menu-logo-col img {
  width: 100%;
}

.menu-page-col {
  vertical-align: bottom;
  text-align: right;
  font-family: 'chivoblack';
  color: #9e9fa4;
  font-size: 0.8em;
  letter-spacing: 0.03em;
  padding-right: 2%;
}

.menu-item {
  border-top: 1px dotted #000;
  width: 100%;
  margin-top: 1em;
  padding-top: 0.3em;
}

.menu-page:last-of-type {
  margin-bottom: 4em;
}

.menu-page:last-of-type .menu-item:last-of-type {
  border: none;
  margin-top: 1.5em;
}

.bolded {
  font-family: 'chivoblack';
}

.item-line {
  position: relative;
}

.item-left {
  width: 75%;
}

.menu-item .item-line:first-child {
  font-family: 'latoblack';
}

.item-right {
  position: absolute;
  top: 0;
  right: 0;
}

/* About */
#about_banner {
  width: 109.35%;
  position: relative;
  left: -5%;
  margin-bottom: 2em;
}

#about_banner img {
  width: 100%;
}

#about_info {
  text-align: left;
  font-size: 0.86em;
  margin-bottom: 2.5em;
}

#about_info p {
  margin: 1.4em 0;
}

/* News */
.news-item {
  position: relative;
  margin: 0 auto 6em;
  font-size: 0.7em;
}
@media screen and (max-width: 768px) {
  .news-item {
    margin: 0 auto 30px;
  }
}

.news-item:last-of-type {
  margin-bottom: 4em;
}

.news-image-container img {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .news-image-container img {
    max-width: 62%;
    text-align: center;
  }
}

.news-image-container .caption {
  padding: 10px 0 0 0;
  max-width: 62%;
  width: 601px;
  margin: 0 auto;
  text-align: right;
  font-family: 'latoregular';
  font-size: 14px;
}

.news-item h1 {
  font-size: 38px;
  margin: 20px auto 0;
}
@media screen and (min-width: 768px) {
  .news-item h1 {
    max-width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .news-item h1 {
    font-size: 24px;
  }
}

.news-item .regular {
  font-family: 'latoregular';
  width: 50%;
  margin: 1em auto;
}

.news-headline.quote {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .news-headline.quote h1 {
    max-width: 80%;
  }
}
.news-headline.quote span {
  text-align: right;
  font-family: 'latoblack';
  padding: 8px 0 0;
  font-size: 22px;
  display: block;
}
@media screen and (max-width: 768px) {
  .news-headline.quote span {
    font-size: 16px;
  }
}

.news-button {
  margin-top: 1.5em;
}
@media screen and (max-width: 768px) {
  .news-button {
    margin-top: 0px;
  }
}

.news-button a {
  color: #fff;
  background-color: #000;
  padding: 0.3em 1em;
  margin: 0 10px;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .news-button a {
    margin: 10px;
    padding: 10px;
    font-size: 14px;
  }
}

/* Location */
#location_map {
  background-color: #e9e5dc;
  position: relative;
  margin: auto;
  margin-bottom: 1.5em;
  width: 92%;
  height: 20em;
}

#location_fallback_image {
  width: 100%;
  height: 100%;
}

#location_info {
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  #location_info {
    font-size: 18px;
  }
}

#location_small {
  margin-top: 1.2em;
  font-size: 0.8em;
}

#contact_info {
  position: relative;
  margin: 2.5em auto 2em;
}
@media screen and (max-width: 768px) {
  #contact_info {
    text-align: center;
  }
}

#contact_info > * {
  display: inline-block;
  vertical-align: top;
}

#contact_info * {
  box-sizing: border-box;
}

.contact-block-inner {
  position: relative;
}
@media screen and (min-width: 768px) {
  .contact-block-inner {
    width: 6em;
  }
}

.contact-text {
  white-space: nowrap;
  position: relative;
  font-size: 19px;
  margin-top: 0.45em;
  text-align: center;
}

.contact-block .square-button {
  width: 100%;
}

#contact_phone {
  left: 50%;
}

#contact_phone .square-button {
  background-color: #ed1c24;
}

#contact_phone .contact-block-inner {
  right: 50%;
}

#contact_fax {
  right: 0;
}

#contact_fax .square-button {
  background-color: #f89b5a;
}

#no_phone_orders {
  font-family: 'lobster_twobold';
  text-align: center;
  font-size: 46px;
  margin: 5px 40px;
}
@media screen and (max-width: 768px) {
  #no_phone_orders {
    font-size: 24px;
    margin: 20px 0;
  }
}

/* Article */
article {
  text-align: left;
  margin: 0 auto 50px;
  max-width: 850px;
  padding: 0 30px;
}

article h1 {
  font-size: 48px;
  text-align: center;
}

article small {
  text-align: center;
  display: block;
  font-size: 16px;
  margin: 0 0 30px;
}

article p {
  font-size: 16px;
  margin: 0 0 15px;
  line-height: 26px;
}

article img[style*='float: left'] {
  margin: 0 20px 20px 0;
}

/* Footer */
#social {
  position: relative;
  margin: 1.5em auto 0;
  white-space: nowrap;
  width: 50%;
  min-width: 17em;
  height: 3em;
}

#social img {
  height: 100%;
  margin: 0 0.4em;
}
@media screen and (max-width: 768px) {
  #social img {
    height: 70%;
  }
}

#motto {
  font-size: 2.5em;
}
@media screen and (max-width: 768px) {
  #motto {
    font-size: 24px;
  }
}

#legal_info {
  margin: 5.5em 0 4em;
}
@media screen and (max-width: 768px) {
  #legal_info {
    margin: 30px 0;
  }
}

#privacy_notice {
  color: #f00;
  margin-bottom: 1.2em;
}

#privacy_notice a {
  text-decoration: underline;
}

.order-online {
  margin: 40px auto 0px;
  font-size: 23px;
  position: relative;
}
.order-online h3 {
  margin-bottom: 30px;
}
.order-online img {
  position: absolute;
  width: 300px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 54px;
  display: none;
}
.order-online--buttons-block h4 {
  margin: 0 0 5px;
  text-decoration: underline;
  font-size: 30px;
}
.order-online--buttons-block p {
  font-size: 25px;
  line-height: 32px;
}
@media screen and (min-width: 768px) {
  .order-online--buttons {
    display: flex;
    margin: 70px 0 0;
    justify-content: space-between;
  }
  .order-online--buttons-block {
    margin: 20px 0 0px;
    max-width: 490px;
  }
  .order-online--buttons-block:first-child {
    max-width: 450px;
  }
  .order-online img {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .order-online {
    font-size: 15px;
    margin: 20px auto 35px;
  }
}
.order-online .subtitle {
  font-size: 22px;
  margin-bottom: 25px;
}
.order-online--buttons-block {
  margin-bottom: 40px;
}
.order-online h3 {
  font-size: 48px;
}
@media screen and (max-width: 768px) {
  .order-online h3 {
    font-size: 32px;
  }
}
